import { useState, useCallback, useLayoutEffect } from "react";
import Nav from "../components/nav";
import PortalDrawer from "../components/portal-drawer";
import Head from "../components/head";
import Footer from "../components/footer";
import TextPlaceHolder from "../components/textPlaceHolder";
import Border from "../components/border";

const PartenaireItem = ({ image, company, text }) => {

  return (
    <div className="flex flex-col justify-between items-start">
      <div className="flex flex-row justify-start items-start w-[100%] py-[3%]">
        <img className="w-[150px] mr-[5vh]" src={image} />
        <div className="h-full w-[85vh]">
          <div className="text-black h-[15vh] w-[100%] flex items-start justify-start text-[20px] font-bold">
            {company}
          </div>
          <div className="text-black h-fit w-[100%] flex items-center justify-start">
            {text}
          </div>
        </div>
      </div>
      <div className="w-[100%] h-[2px] bg-softBlack" />
    </div>
  );
};

const Partenaires = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const openNav = useCallback(() => {
    setNavOpen(true);
  }, []);

  const closeNav = useCallback(() => {
    setNavOpen(false);
  }, []);

  useLayoutEffect(() => {

    const handleScroll = () => {
      const positions = partners.map((_categories, index) => {
        const element = document.getElementById(index + "E");
        const text = document.getElementById(index + "T");
        const rectE = element.getBoundingClientRect();
        const rectT = text.getBoundingClientRect();
        const top = rectT.top - rectE.top
        return top;
      });
      for (let i = 0; i < positions.length; i++) {
        if (positions[i] >= 0) {
          setActiveIndex(i);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const partners = [
    [// Partenaires Officiels
    ],
    [
      { key: "CVEC", image: "../sponsor/logo_cvec.png", company: "CVEC", text: "La qualité de la vie étudiante et de campus est un facteur de réussite des étudiants et d’attractivité des établissements. C’est pourquoi cette contribution est destinée à favoriser l’accueil et l’accompagnement social, sanitaire, culturel et sportif des étudiants et à conforter les actions de prévention et d’éducation à la santé réalisées à leur intention, en abondant les moyens déjà alloués par les établissements." },
      { key: "CENTRALE LYON", image: "../sponsor/logo_ecl.png", company: "CENTRALE LYON", text: "Centrale Lyon est une grande école d'ingénieurs française, spécialisée dans les sciences de l'ingénieur et les technologies de pointe. Elle forme des ingénieurs de haut niveau capables de relever les défis de demain." },
      { key: "METROPOLE DE LYON", image: "../sponsor/logo_Metropole_Lyon.png", company: "METROPOLE DE LYON", text: "La Métropole de Lyon est une collectivité territoriale française regroupant 59 communes. Elle est chargée d'assurer le développement économique, social et culturel de son territoire, ainsi que l'aménagement urbain et la protection de l'environnement." },
      { key: "REGION", image: "../sponsor/logo_region.png", company: "REGION AUVERGNE-RHONE-ALPES", text:"La Région Auvergne-Rhône-Alpes, regroupant plusieurs départements, est une institution territoriale engagée dans le développement économique, social et culturel de son territoire. Son action vise à favoriser l'épanouissement de ses habitants, à soutenir les initiatives locales et à promouvoir l'attractivité régionale."},
      { key: "ECULLY", image: "../sponsor/logo_ecully.jpg", company: "ECULLY", text:"La ville d'Ecully, située dans la métropole de Lyon, est une commune dynamique et attractive. Elle offre un cadre de vie agréable, alliant modernité et tradition. Engagée dans le développement local, Ecully s'attache à répondre aux besoins de ses habitants et à promouvoir son patrimoine naturel et culturel."}
    ],
    [
      // Partenaires Logistiques
     ],
  ];

  const type = ["Officiel", "Institutionnel", "Logistique"]

  return (
    <>
      <div className="bg-gray-100 w-screen flex flex-col items-start justify-start text-white font-menu-main1">

        <div className="w-screen h-[50vh] bg-softBlack">
          <Head nav={openNav} name={"Partenaires"} />
        </div>

        <div className="h-screen text-white font-menu-main1">
          <img
            className="w-screen h-screen object-cover rounded-bl-3xxl"
            src="../clapping.png"
          />

          <div className="absolute top-[50vh] left-0 w-screen h-screen flex items-center justify-center">
            <div className="text-center leading-[50px] text-2xl lg:leading-[200px] lg:text-4xl uppercase font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              Des partenaires engagés
            </div>
          </div>

        </div>

        <Border />

        <TextPlaceHolder
          text="Les partenaires sont des acteurs clés de la réalisation et du
      financement du Challenge 2024. Leur contribution peut être financière ou technique, à travers
      la fourniture de produits et de services : ils collaborent
      ainsi à l’organisation du Challenge 2024." />

        <Border />

        <div className="flex flex-col md:flex-row justify-evenly items-start w-screen py-[10%] h-fit">

          <div className="md:sticky top-[30%] w-[100%] md:w-[40%] h-[50vh] flex flex-row items-start justify-start">

            <img className="w-[50%] h-[50%]" src="../logo.svg" />

            <div className="w-[40%] h-full flex flex-col item-center items-start justify-evenly">
              {type.map((type, index) => (
                <div className="flex flex-row items-center justify-between w-full h-full">
                  <div id={index + "T"} className={"text-softBlack text-[20px]" + (activeIndex == index ? " md:text-[30px] md:font-bold" : " text-[20px]")}>{type}</div>
                  <img className={(activeIndex == index ? " md:w-[20px]" : " md:w-[10px]")} src="../stripe_right.svg" alt="" />
                </div>

              ))}
            </div>

          </div>

          <div className="w-[100%] md:w-[40%] flex flex-col">

            <div className="w-[100%] h-[2px] bg-softBlack" />
            Ò
            {partners.map((categories, index) => (
              <div id={index + "E"} className="snap-mandatory snap-y ">
                {categories.map(({ company, image, text }) => (
                  <div className="snap-center">
                    <PartenaireItem key={company} image={image} company={company} text={text} />
                  </div>
                ))}
              </div>
            ))}

          </div>
        </div>

        <Border />

        <TextPlaceHolder
          text="Le Challenge est fier de compter sur le soutien de partenaires
            engagés qui nous accompagnent dans notre événement de l'année
            2024. Leur engagement est précieux pour nous et nous permet de
            rayonner nos valeurs sportives. Nous sommes convaincus que le
            sport est un vecteur de valeurs positives et nous souhaitons
            partager ces valeurs avec nos partenaires. Grâce à leur soutien,
            nous sommes en mesure de proposer un événement de qualité qui
            réunit des étudiants de tous horizons et leur offre l'opportunité
            de se dépasser et de s'épanouir grâce au sport. Nous sommes
            reconnaissants envers nos partenaires pour leur implication et
            leur engagement à nos côtés, et nous espérons que notre
            collaboration continuera à se développer dans les années à venir." />

        <Border />

        <Footer />

      </div>

      {
        isNavOpen && (
          <PortalDrawer
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Left"
            onOutsideClick={closeNav}
          >
            <Nav onClose={closeNav} />
          </PortalDrawer>
        )
      }

    </>
  );
};

export default Partenaires;
