import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./menuItems";
import MenuSocial from "./menuSocial";

const Nav = ({ onClose }) => {

  const isDevenirPartenaireActive = location.pathname == "/devenirpartenaire";
  const isPartenaireActive = location.pathname == "/partenaires";
  const isPodiumActive = location.pathname == "/podium";
  const isRediffusionActive = location.pathname == "/rediffusion";

  const [isOpen, setIsOpen] = useState(true);

  const onRetrouvezNousSurYoutubeClick = useCallback(() => { window.open("https://www.youtube.com/user/ChallengeECLyon"); }, []);
  const onRetrouvezNousSurTwitterClick = useCallback(() => { window.open("https://twitter.com/challenge_ecl"); }, []);
  const onRetrouvezNousSurLinkedInClick = useCallback(() => { window.open("https://www.linkedin.com/company/challenge-centrale-lyon/"); }, []);
  const onRetrouvezNousSurFacebookClick = useCallback(() => { window.open("https://www.facebook.com/ChallengeCentraleLyon"); }, []);
  const onRetrouvezNousSurInstagramClick = useCallback(() => { window.open("https://www.instagram.com/challengecentralelyon/"); }, []);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      onClose();
    }, 600);
  };

  return (
    <div className={(isOpen ? "animate-[0.5s_ease_0s_1_normal_forwards_slide-in-left] " : "animate-[0.6s_ease_0s_1_normal_forwards_slide-out-right] ") + "bg-white w-[100%] h-fit md:w-[50%] lg:w-[30%] text-white font-menu-main1 overflow-x-hidden"}>

      <div className={(isOpen ? "animate-[0.6s_ease_0s_1_normal_forwards_slide-in-left] " : "animate-[0.5s_ease_0s_1_normal_forwards_slide-out-right] ") + "bg-softBlack h-fill flex flex-col items-start justify-start w-[99%]"}>

        <div className="w-full sticky bg-softBlack h-[80px] top-0 z-10">
          <button
            className="cursor-pointer [border:none] rounded-base w-[50px] h-[50px] grid place-items-center m-[10px] bg-white"
            onClick={handleClose}>
            <img className="w-[30px] h-[30px]" src="../croix.svg" />
          </button>
        </div>

        <div className="w-full sticky top-[80px] h-[30px] bg-gradient-to-b from-softBlack z-10" />

        <div className="w-full flex flex-col gap-[40px] my-[40px]">

          <MenuItems text="Accueil" link="/accueil" />
          <MenuItems text="Participer" link="/participer" />
          <MenuItems text="Nos engagements" link="/engagements" />
          <MenuItems text="L'événement" link="/evenement" />

        </div>

        <Link
          className="cursor-pointer [text-decoration:none] relative h-[55 px] text-[inherit] flex flex-col items-center justify-between w-full text-2xl md:text-sm"
          >


          <div className="w-[90%] m-[5%] flex flex-row items-center justify-start h-full">




          </div>

          <div className="w-[90%] h-[5px] md:h-[3px] lg:h-[1px] bg-white" />

        </Link>

        <div className="flex flex-col items-center justify-start gap-[30px] text-sm md:text-16xs w-[100%] my-[50px]">

          <Link
            className="cursor-pointer [text-decoration:none] w-[90%] mx-[5%] flex flex-row items-center justify-start text-[inherit]"
            to="/devenirpartenaire">

            <div className={"w-full" + (isDevenirPartenaireActive ? " text-orange" : "")}>Devenir Partenaire Challenge 2024</div>

            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.36194 6.97402L1.91694 12.96H0.361938L4.26194 7.65802V6.29002L0.361938 0.960022H1.91694L6.36194 6.97402Z" fill={(isDevenirPartenaireActive ? "orange" : "white")} />
            </svg>

          </Link>

          <Link
            className="cursor-pointer [text-decoration:none] w-[90%] mx-[5%] flex flex-row items-center justify-start text-[inherit]"
            to="/partenaires">

            <div className={"w-full" + (isPartenaireActive ? " text-orange" : "")}>Les Partenaires</div>
            
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.36194 6.97402L1.91694 12.96H0.361938L4.26194 7.65802V6.29002L0.361938 0.960022H1.91694L6.36194 6.97402Z" fill={(isPartenaireActive ? "orange" : "white")} />
            </svg>
          </Link>

          <Link
            className="cursor-pointer [text-decoration:none] w-[90%] mx-[5%] flex flex-row items-center justify-start text-[inherit]"
            to="/rediffusion">

            <div className={"w-full" + (isRediffusionActive ? " text-orange" : "")}>Rediffusion</div>
            
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.36194 6.97402L1.91694 12.96H0.361938L4.26194 7.65802V6.29002L0.361938 0.960022H1.91694L6.36194 6.97402Z" fill={(isPartenaireActive ? "orange" : "white")} />
            </svg>
          </Link>

          <Link
            className="cursor-pointer [text-decoration:none] w-[90%] mx-[5%] flex flex-row items-center justify-start text-[inherit]"
            to="/podium">

            <div className={"w-full" + (isPodiumActive ? " text-orange" : "")}>Podium</div>
            
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.36194 6.97402L1.91694 12.96H0.361938L4.26194 7.65802V6.29002L0.361938 0.960022H1.91694L6.36194 6.97402Z" fill={(isPartenaireActive ? "orange" : "white")} />
            </svg>
          </Link>

        </div>

        <div className="flex flex-row my-[40px] items-center justify-evenly w-full">

          <MenuSocial link="../social/youtube.svg" click={onRetrouvezNousSurYoutubeClick} />
          <MenuSocial link="../social/twitter.svg" click={onRetrouvezNousSurTwitterClick} />
          <MenuSocial link="../social/linkedin.svg" click={onRetrouvezNousSurLinkedInClick} />
          <MenuSocial link="../social/facebook.svg" click={onRetrouvezNousSurFacebookClick} />
          <MenuSocial link="../social/instagram.svg" click={onRetrouvezNousSurInstagramClick} />

        </div>

      </div>
    </div>
  );
};

export default Nav;
