import Countdown from "./countdown";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Head = ({ nav, name }) => {

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="top-0 left-0 absolute w-screen h-[25vh] text-center text-9xs font-source-sans-pro flex flex-row items-start justify-between">

            <div className="fixed z-40 top-0 left-0 flex flex-row py-2.5 px-[30px] gap-[15px] items-center justify-start">

                <button className="border-solid border-1 border-black cursor-pointer  bg-white rounded-base w-[40px] h-[40px] md:w-[50px] md:h-[50px] flex flex-col justify-center gap-[4px] hover:gap-[6px] items-center transition-all duration-200 ease-in-out"
                    onClick={nav}>

                    <div className="w-[50%] md:w-[30%] bg-black h-[2px]" />
                    <div className="w-[50%] md:w-[30%] bg-black h-[2px]" />
                    <div className="w-[50%] md:w-[30%] bg-black h-[2px]" />

                </button>

                <p className={"invisible lg:visible text-white " + (scrollPosition > 100 ? 'lg:invisible' : '')}>{name}</p>

            </div>

            <div className="absolute top-0 left-[25%] w-[50%] flex flex-col items-center justify-center font-menu-main">

                <img className="w-[100%] object-cover" src="../challenge_text.png" />

                <Countdown />

            </div>



        </div>
    );
};

export default Head;